import "lazysizes";

var html_classes = document.documentElement.classList;
html_classes.remove("no-js");
html_classes.add("js");

window.onload = function() {       
	setupSlideMenu();
	const map_click = document.getElementById("map-click");
	if (map_click) {
		map_click.addEventListener("click",() => {
			// shield the map on mobile for easy scrolling
			map_click.style.display = "none";
		});
	}
	fixIphoneFixedBanner();
};

export function setupSlideMenu(){
	const menu_buttons = [...document.querySelectorAll("#mobile-menu, .slide-menu h3")];
	menu_buttons.forEach(el => {
		el.addEventListener("click", ()=>{
			const body = document.querySelector("body");
			body.classList.toggle("slide-menu-active");
		});
	});

	const menu_items = [...document.querySelectorAll(".slide-menu li")];
	menu_items.forEach((element) => {		
		if ([...element.querySelectorAll("ul")].length > 0) {
			element.insertAdjacentHTML("beforeend","<span class=\"expand\"><i class=\"nav-arrow\"></i></span>");
		}
	});
	const menu_expanders = [...document.querySelectorAll(".slide-menu .expand")];
	menu_expanders.forEach(el => {
		el.addEventListener("click", (e)=> {
			e.stopPropagation();
			el.closest("li").classList.toggle("expand-active");
		});
	});
}


export function fixIphoneFixedBanner(){
	let top_header = document.querySelector(".t-home header.top");
	if (!top_header) return;
	// Fix for iOS fixed banner issue
	// First detect if device won't support fixed background images
	// https://stackoverflow.com/a/63698785/1880657
	const isSupported = !CSS.supports("(-webkit-overflow-scrolling: touch)");
	if (isSupported) return;



	// Now create a new fixed element with a non fixed background to emulate the effect	
	const fixed_banner = document.createElement("div");
	fixed_banner.classList.add("iphone-fixed-bg");
	fixed_banner.style.backgroundImage = top_header.style.backgroundImage;
	top_header.insertAdjacentElement("beforeBegin", fixed_banner);
	top_header.style.backgroundImage = "none";
	top_header.style.backgroundColor = "transparent";
	document.querySelector("main").style.backgroundColor = "white";
}

//add style attribute with background image when lazyload event fired
// Setup background-image elements with class="lazyload" and data-bg-url-"https://domain.com/image/url/file.jpg".
// When they become visible, the lazy loader will fire this, and we will update the background image style.
// A low res fallback background image style could be set, so that something is visible before this triggers.
document.addEventListener("lazybeforeunveil", function(e){
	var container = e.target;
	var bgUrl = container.dataset.bgUrl;
	if (bgUrl) {
		container.style.backgroundImage = "url('" + bgUrl + "')";
		delete container.dataset.bgUrl;
	}
});